import { FC, useEffect } from 'react';

import { navigate } from '@reach/router';
import { withPrefix } from 'gatsby';

const NotFoundPage: FC = () => {
  useEffect(() => {
    navigate(withPrefix('/'));
  }, []);
  return null;
};

export default NotFoundPage;
